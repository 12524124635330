@import 'static/_variables.scss';

.ai-chat {
    background: linear-gradient(180deg, #334E74 0%, #02153F 100%);
    width: 760px;
    height: 680px;
    color: $Pf08Color;
    border-radius: 4px;
    border: 1px solid #1F5284;
    // transition: all 0.3s ease-out;
    overflow: hidden;

    .title-container {
        background: linear-gradient(90deg, #2CD6F7 0%, #3889FF 100%);
        height: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px 4px 0px 0px;

        .title-btn {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .title-icon {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;

                .icon {
                    height: 20px;
                    font-weight: 700;
                }
            }

            .anticon-close {
                color: #fff;
            }
        }

        .title-text {
            font-family: Alibaba PuHuiTi;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            text-align: left;
            color: $PfffColor;
            display: flex;
            align-items: center;

            .title-icon {
                width: 60px;
                padding: 0 5px;
            }
        }

    }

    .chat {
        display: flex;

        .chat-left {
            flex: 1;
            height: 100%;
            background: linear-gradient(180deg, #334E74 0%, #02153F 100%);
            padding: 10px;
            display: flex;
            flex-direction: column;
            width: 70%;

            .chat-content {
                background: linear-gradient(180deg, #152B46 0%, #061A32 100%);
                border: 1px solid #1F5284;
                height: 76%;
                margin-bottom: 5px;
                overflow-y: auto;
                padding: 20px;
                width: 100%;

                .chat-answer-item {
                    padding-top: 20px;
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .title-icon {
                        width: 40px;
                        margin-right: 10px;
                        align-self: start;
                    }

                    .chat-item-content {
                        background: linear-gradient(90deg, rgba(92, 144, 207, 0.2) 23.05%, rgba(34, 171, 178, 0.2) 100%);
                        padding: 10px 15px;
                        border-radius: 0px 16px 16px 16px;
                        word-wrap: break-word;
                        overflow-y: auto;
                        overflow-x: hidden;

                        .no-data {
                            width: 100%;
                            height: 80px;
                            text-align: center;
                            line-height: 80px;
                            color: 'rgba(255, 255, 255, 0.6)';
                        }

                        .chart-title {
                            margin: 10px 0;
                        }

                        .chart-btn {
                            color: #2CD6F7;
                            text-align: right;
                            display: flex;
                            justify-content: end;
                            align-items: center;

                            .full-screen {
                                display: flex;
                                padding: 0 10px;

                                .icon {
                                    margin-left: 5px;
                                    display: block;
                                    position: relative;
                                    top: 1px;
                                }
                            }
                        }

                        .chart-box {
                            margin-bottom: 20px;
                        }
                    }

                    .width-80 {
                        width: 80%;
                    }

                    .pre-wrap {
                        white-space: pre-wrap;
                    }

                    .loading-text {
                        >span {
                            display: inline-block;
                            width: 6px;
                            height: 6px;
                            background-color: rgb(82, 140, 206);
                            border-radius: 50%;
                            margin-bottom: 3px;

                            &+span {
                                margin-left: 5px;
                            }
                        }

                        .dot1 {
                            animation: dot 1s infinite;
                        }

                        .dot2 {
                            animation: dot 1s infinite 0.2s;
                        }

                        .dot3 {
                            animation: dot 1s infinite 0.4s;
                        }

                        .dot4 {
                            animation: dot 1s infinite 0.6s;
                        }

                        @keyframes dot {
                            0% {
                                opacity: 0;
                            }

                            50% {
                                opacity: 1;
                            }

                            100% {
                                opacity: 0;
                            }
                        }
                    }
                }

                .chat-question-item {
                    padding-top: 20px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .title-icon {
                        width: 35px;
                        margin-left: 10px;
                        align-self: start;
                    }

                    .chat-item-content {
                        background: linear-gradient(90deg, rgba(255, 136, 56, 0.8) -0.51%, rgba(241, 229, 135, 0.8) 100%);
                        color: rgba(2, 21, 63, 1);
                        padding: 10px 15px;
                        border-radius: 16px 0px 16px 16px;
                        word-wrap: break-word;
                        overflow-y: auto;
                    }

                    .pre-wrap {
                        white-space: pre-wrap;
                    }
                }
            }

            .chat-divider {
                margin: 0px;
                margin-bottom: 5px;
                background: #2CD6F733;
            }

            .chat-input {
                background: linear-gradient(180deg, #152B46 0%, #061A32 100%);
                border: 1px solid #1F5284;
                flex: 1;
                position: relative;
                padding-bottom: 45px;

                .send-input {
                    border: 0px;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .chat-send-btn {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    width: 80px;
                    height: 28px;
                    line-height: 28px;
                    padding: 0px;

                    &:hover {
                        color: $Pf08Color;
                    }
                }

                .chat-send-disabled {
                    background-color: rgba(148, 175, 215, 1) !important;
                    color: $Pf08Color !important;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    width: 80px;
                    height: 28px;
                    line-height: 28px;
                    padding: 0px;

                    &:hover {
                        color: $Pf08Color !important;
                    }
                }
            }
        }

        .common-problems {
            width: 30%;
            height: 100%;
            border: 1px solid rgba(31, 82, 132, 1);
            background: linear-gradient(0deg, #113751, #113751),
                linear-gradient(90deg, rgba(92, 144, 207, 0.16) 0%, rgba(34, 171, 178, 0.16) 100%);
            // transition: all 0.3s ease-out;
            // overflow: hidden;

            .common-retract {
                color: $P2cColor;
                font-size: 14px;
                padding: 5px;
                border-bottom: 1px solid rgba(31, 82, 132, 1);
            }

            .common-list {
                padding: 15px;
                overflow-y: auto;

                .title {
                    font-family: Alibaba PuHuiTi;
                    color: $PfffColor;
                    font-size: 16px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;

                    .title-icon {
                        width: 30px;
                        margin-right: 10px;
                    }
                }

                .list {
                    margin-top: 20px;

                    .item {
                        height: 45px;
                        background: linear-gradient(90deg, rgba(92, 144, 207, 0.4) 23.05%, rgba(34, 171, 178, 0.4) 100%);
                        border-radius: 0px 10px 10px 10px;
                        display: flex;
                        justify-content: space-between;
                        padding: 0 20px;
                        color: $P2cColor;
                        font-size: 14px;
                        margin-bottom: 10px;

                        &:hover {
                            background: linear-gradient(90deg, rgba(92, 144, 207, 0.8) 23.05%, rgba(34, 171, 178, 0.8) 100%);
                            color: $P2c08Color;
                        }

                        .title {
                            color: $P2cColor;
                            font-size: 14px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                            line-height: 45px;
                        }
                    }
                }
            }
        }

        .retract-btn {
            width: 40px;
            height: 140px;
            background: linear-gradient(0deg, #113751, #113751),
                linear-gradient(90deg, rgba(92, 144, 207, 0.1) 0%, rgba(34, 171, 178, 0.1) 100%);
            border: 1px solid rgba(31, 82, 132, 1);
            border-radius: 0px 0px 0px 4px;
            padding: 12px;
            color: $P2cColor;
            line-height: 22px;
        }
    }

}

.chart-modal {
    width: 1300px !important;

    .ant-modal-title {
        background: rgba(20, 42, 69, 1) !important;
        color: $PfffColor;
        text-align: center;
        font-weight: 400;
    }

    .ant-modal-content {
        background: rgba(20, 42, 69, 1) !important;
    }

    .ant-modal-close {
        color: $P2c08Color;

        &:hover {
            color: #0958d9;
        }
    }
}