.main-layout {
    height: 100%;
    overflow-y: auto;

    .header {
        background: transparent;
        padding: 0;
        margin: 0 16px;
        height: 122px;

        .header-title {
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            h1 {
                min-width: 363px;
                font-family: "Microsoft YaHei";
                font-size: 30px;
                font-weight: 600;
                line-height: 40px;
                color: rgb(255, 255, 255);
                letter-spacing: 3px;
                user-select: none;

                .env {
                    color: red;
                    font-size: 16px;
                }
            }

            .user-info {
                position: absolute;
                right: 30px;
                cursor: pointer;

                .ant-space {
                    color: white;
                }
            }
        }

        .header-nav {
            height: 36px;
            margin: 16px 0px;
            display: flex;
            justify-content: space-between;

            ul {
                display: flex;
                height: 100%;

                li {
                    height: 100%;
                    line-height: 36px;
                    width: 120px;
                    color: rgb(31, 198, 255);
                    font-size: 14px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 8px;
                    padding: 0 10px;

                    span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                .active {
                    color: white;
                }
            }

            .global-filter {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .ant-layout-content {
        // background-color: rgb(5, 55, 82);
        margin: 20px;
    }
}

.popover-box {
    color: rgba(255, 255, 255, 0.8);
    width: 170px;

    div {
        color: rgba(255, 255, 255, 0.8);
        padding: 0 10px;
        line-height: 32px;
        text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    div:nth-child(1) {
        padding-top: 6px;
    }

    .change-info {
        color: rgb(56, 225, 255);
        cursor: pointer;
        border-top: 1px solid rgba(0, 137, 255, 0.2);

        >a {
            color: rgba(255, 255, 255, 0.8) !important;
        }

        .active {
            color: rgb(56, 225, 255);
        }

        &:hover {
            background: rgba(44, 214, 247, 0.1);
        }
    }

    .logout {
        padding: 0;
        color: rgb(56, 225, 255);
        text-align: center;
        cursor: pointer;
        border-top: 1px solid rgba(0, 137, 255, 0.2);

        &:hover {
            background: rgba(44, 214, 247, 0.1);
        }
    }
}

.userinfo-popover {
    .ant-popover-inner {
        width: 170px;
        padding: 0px;
    }
}